.cls-1,.cls-3{fill:#252927;}
.cls-1,.cls-4,.cls-5,.cls-8{stroke:#fff;}
.cls-1,.cls-2,.cls-4,.cls-5,.cls-7,.cls-8{stroke-miterlimit:10;}
.cls-1,.cls-4,.cls-5{stroke-width:3px;}
.cls-2{fill:#fff;stroke-width:0.5px;}
.cls-2,.cls-7{stroke:#000;}
.cls-4{fill:#ed9a5c;}
.cls-5{fill:#ff703c;}
.cls-6{fill:#b57755;}
.cls-7{fill:#ffe2b0;}
.cls-7,.cls-8{stroke-width:2px;}
.cls-8{fill:#ff9c3c;}

/*  -------------------------------------------------------------- MENU ------------------------------------------------------------ */

nav{
    z-index: 10;
    width: 100vw;
    height: 12vmin;
    position: fixed;
    top: 0vh;
    left: 0;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 1.4vw;
    display: flex;
    flex-direction: row;
    transition-duration: 0.6s;
}

#menuSVG{
    stroke:#edf2ef;
    stroke-width:2px;
    stroke-dashoffset: 1000px;
    width: 0%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0%;
}
#menuPath{
    height: 100%;
    width: 100%;
}


#divLogo>:first-child{
    display: grid;
    justify-self: end;
}

#logoFox{
    max-height: 80%;
    max-width: 40%;
    opacity: 0.85;
    transform: scale(0);
    rotate: 90deg;
    align-self: center;
    justify-self: end;
    margin-right: 10px;
}


.Noeud{
    opacity: 0;
    transition-duration: 0.4s;
}
#name{
    transform: translateX(-250px);
    margin-left: 10px;
    align-self: center;
    justify-self: left;
    white-space: nowrap;
}
#divName{
    overflow: hidden;
    display: grid;
    grid-template-rows: 12vmin;
}

#dash {
    display: inline-block;
    height: 25px;
    width: 2px;
    margin-left: 1px;
    background-color: #edf2ef;
    opacity: 0;
    align-self: center;
    justify-self: left;
    transition-duration: 0.6s;
  }

#divLogo{
    height: 100%;
    margin-left: 2%;
    width: 21vw;
    display: grid;
    grid-template-areas: "a b";
    grid-template-columns: 1fr 2fr;
}
#divLogo>*>img{
    max-height: 11vmin;
    align-self: center;
}
#linkMenu{
    position: absolute;
    width: 38vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    right: 0;
    z-index: 3;
    opacity: 1;
}
#linkMenu>div{
    padding: 3%;
}
#availableLanguages{
    opacity: 0;
    position: absolute;
    width: 21vw;
    height: 100%;
    display: grid;
    grid-template-areas: "a b";
    right: 0;
    z-index: 1;
}

#availableLanguages>p{
    cursor: pointer;
    align-self: center;
}
#availableLanguages>p:hover{
    text-decoration: underline;
}
.menuItem{
    display: grid;
    overflow: hidden;
    align-self: center;
}

.menuItem>a{
    align-self: center;
    transform: translateY(200%);
    cursor: pointer;
    color: white;
    text-decoration: none;
    transition-duration: 0.4s;
}
.menuItem>a:hover{
    text-decoration: underline;
    transition-duration: 1s;
}



nav:hover{
    background-color: red;
    color: black;
    transition-duration: 0.6s;
}

nav:hover .menuItem>a, nav:hover #availableLanguages, nav:hover #availableLanguages>p{
    color: black;
}

nav:hover .Noeud{
    opacity: 1;
    transition-duration: 0.6s;
}
nav:hover #menuSVG{
    stroke:#000000;
    transition-duration: 0.6s;
}



/* ---------------------------------------------------------------------------------------------------------------------- */

#ScrollLine{
    position: fixed;
    display: grid;
    grid-template-areas: "a" "b" "c" "d";
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 19vh;
    right: 0;
    top: 50%;
    z-index: 9;
    transform: translate(100%, -9.5vh);
}
.ScrollLine{
    height: 0.3vh;
    width: 1.8vw;
    background-color: #edf2ef;
    justify-self: right;
    align-self: center;
    clip-path: inset(0 0 0 0 round 25% 0 25% 0);
    transition-duration: 1s;
}
#ScrollLine>a{
    height: 2vh;
    display: grid;
}
.ScrollLine.active{
    height: 0.4vh;
    width: 3vw;
    transition-duration: 1s;
}

@media (max-width: 768px) {
    .ScrollLine {
      display: none;
    }
    #linkMenu{
        flex-direction: column;
    }
    nav{
        height: 60%;
        flex-direction: column-reverse;
        transform: translateY(-80%);
    }
    nav:hover{
        background-color: rgb(77, 168, 163);
        color: white;
    }
    nav:hover .Noeud{
        opacity: 0;
        transition-duration: 0s;
    }
    nav:hover #menuSVG{
        stroke:#edf2ef;
        transition-duration: 0s;
    }
    nav.active #menuSVG{
        stroke:#000000;
        transition-duration: 0.6s;
    }
    nav.active{
        transform: translateY(0);
        background-color: white;
        color: black;
    }
    nav.active .menuItem>a, nav:hover #availableLanguages, nav:hover #availableLanguages>p{
        color: black;
    }
    nav.active .Noeud{
        opacity: 1;
        transition-duration: 0.6s;
    }
    #linkMenu{
        width: 100%;
        height: 65%;
        top: 0;
    }
    #divLogo{
        width: 60%;
        height: 20%;
        align-items: center;
        align-self: center;
    }
    #name{
        font-size: 3vw;
    }
    .menuItem>a{
        transform: translateY(0);
        font-size: 4vw;
    }
    #logoFox{
        z-index: 5;
        transform: scale(1);
        max-height: 60%;
        justify-self: end;
    }
    #closeMenu{
        margin: 10px;
    }
    #availableLanguages{
        display: none;
        width: 100%;
        z-index: 4;
        height: 10%;
        top: 54%;
    }
    #availableLanguages>p{
        margin: 10px;
        padding: 3%;
        font-size: 4vw;
    }
    #fr{
        text-align: end;
    }
  }