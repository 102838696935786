#study{
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    background-color: rgb(77, 168, 163);
    grid-template-areas: "a b c";
    width: 100vw;
    height: auto;
    min-height: 100vh;
}
#study-content{
    background: rgba(75,158,157,1);
    width: 100%;
    grid-area: b;
}