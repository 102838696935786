@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background:  linear-gradient(90deg, rgba(117,217,198,1) 0%, rgba(80,163,162,1) 100%);
  overflow: hidden;
  height: 100vh;
}
p, a, h2, h1, h3, h4 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
#curvO{
    stroke-dasharray : 351;
    stroke-dashoffset : 351;
}
#curvL{
    stroke-dasharray : 1510;
    stroke-dashoffset : 1510;
}
#curvI{
    stroke-dasharray : 200;
    stroke-dashoffset : 200;
}
#curvOM{
    stroke-dasharray : 930;
    stroke-dashoffset : 930;
}

h1{
    height: 20vh;
    width: 40vw;
    z-index: 6;
    position: fixed;
    top: 30vh;
    left: 50vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.cls-1,.cls-3{fill:#252927;}
.cls-1,.cls-4,.cls-5,.cls-8{stroke:#fff;}
.cls-1,.cls-2,.cls-4,.cls-5,.cls-7,.cls-8{stroke-miterlimit:10;}
.cls-1,.cls-4,.cls-5{stroke-width:3px;}
.cls-2{fill:#fff;stroke-width:0.5px;}
.cls-2,.cls-7{stroke:#000;}
.cls-4{fill:#ed9a5c;}
.cls-5{fill:#ff703c;}
.cls-6{fill:#b57755;}
.cls-7{fill:#ffe2b0;}
.cls-7,.cls-8{stroke-width:2px;}
.cls-8{fill:#ff9c3c;}

/*  -------------------------------------------------------------- MENU ------------------------------------------------------------ */

nav{
    z-index: 10;
    width: 100vw;
    height: 12vmin;
    position: fixed;
    top: 0vh;
    left: 0;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 1.4vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    transition-duration: 0.6s;
}

#menuSVG{
    stroke:#edf2ef;
    stroke-width:2px;
    stroke-dashoffset: 1000px;
    width: 0%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0%;
}
#menuPath{
    height: 100%;
    width: 100%;
}


#divLogo>:first-child{
    display: grid;
    justify-self: end;
}

#logoFox{
    max-height: 80%;
    max-width: 40%;
    opacity: 0.85;
    -webkit-transform: scale(0);
            transform: scale(0);
    rotate: 90deg;
    -webkit-align-self: center;
            align-self: center;
    justify-self: end;
    margin-right: 10px;
}


.Noeud{
    opacity: 0;
    transition-duration: 0.4s;
}
#name{
    -webkit-transform: translateX(-250px);
            transform: translateX(-250px);
    margin-left: 10px;
    -webkit-align-self: center;
            align-self: center;
    justify-self: left;
    white-space: nowrap;
}
#divName{
    overflow: hidden;
    display: grid;
    grid-template-rows: 12vmin;
}

#dash {
    display: inline-block;
    height: 25px;
    width: 2px;
    margin-left: 1px;
    background-color: #edf2ef;
    opacity: 0;
    -webkit-align-self: center;
            align-self: center;
    justify-self: left;
    transition-duration: 0.6s;
  }

#divLogo{
    height: 100%;
    margin-left: 2%;
    width: 21vw;
    display: grid;
    grid-template-areas: "a b";
    grid-template-columns: 1fr 2fr;
}
#divLogo>*>img{
    max-height: 11vmin;
    -webkit-align-self: center;
            align-self: center;
}
#linkMenu{
    position: absolute;
    width: 38vw;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    right: 0;
    z-index: 3;
    opacity: 1;
}
#linkMenu>div{
    padding: 3%;
}
#availableLanguages{
    opacity: 0;
    position: absolute;
    width: 21vw;
    height: 100%;
    display: grid;
    grid-template-areas: "a b";
    right: 0;
    z-index: 1;
}

#availableLanguages>p{
    cursor: pointer;
    -webkit-align-self: center;
            align-self: center;
}
#availableLanguages>p:hover{
    text-decoration: underline;
}
.menuItem{
    display: grid;
    overflow: hidden;
    align-self: center;
}

.menuItem>a{
    -webkit-align-self: center;
            align-self: center;
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    cursor: pointer;
    color: white;
    text-decoration: none;
    transition-duration: 0.4s;
}
.menuItem>a:hover{
    text-decoration: underline;
    transition-duration: 1s;
}



nav:hover{
    background-color: red;
    color: black;
    transition-duration: 0.6s;
}

nav:hover .menuItem>a, nav:hover #availableLanguages, nav:hover #availableLanguages>p{
    color: black;
}

nav:hover .Noeud{
    opacity: 1;
    transition-duration: 0.6s;
}
nav:hover #menuSVG{
    stroke:#000000;
    transition-duration: 0.6s;
}



/* ---------------------------------------------------------------------------------------------------------------------- */

#ScrollLine{
    position: fixed;
    display: grid;
    grid-template-areas: "a" "b" "c" "d";
    grid-template-rows: 1fr 1fr 1fr 1fr;
    height: 19vh;
    right: 0;
    top: 50%;
    z-index: 9;
    -webkit-transform: translate(100%, -9.5vh);
            transform: translate(100%, -9.5vh);
}
.ScrollLine{
    height: 0.3vh;
    width: 1.8vw;
    background-color: #edf2ef;
    justify-self: right;
    -webkit-align-self: center;
            align-self: center;
    -webkit-clip-path: inset(0 0 0 0 round 25% 0 25% 0);
            clip-path: inset(0 0 0 0 round 25% 0 25% 0);
    transition-duration: 1s;
}
#ScrollLine>a{
    height: 2vh;
    display: grid;
}
.ScrollLine.active{
    height: 0.4vh;
    width: 3vw;
    transition-duration: 1s;
}

@media (max-width: 768px) {
    .ScrollLine {
      display: none;
    }
    #linkMenu{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    nav{
        height: 60%;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-transform: translateY(-80%);
                transform: translateY(-80%);
    }
    nav:hover{
        background-color: rgb(77, 168, 163);
        color: white;
    }
    nav:hover .Noeud{
        opacity: 0;
        transition-duration: 0s;
    }
    nav:hover #menuSVG{
        stroke:#edf2ef;
        transition-duration: 0s;
    }
    nav.active #menuSVG{
        stroke:#000000;
        transition-duration: 0.6s;
    }
    nav.active{
        -webkit-transform: translateY(0);
                transform: translateY(0);
        background-color: white;
        color: black;
    }
    nav.active .menuItem>a, nav:hover #availableLanguages, nav:hover #availableLanguages>p{
        color: black;
    }
    nav.active .Noeud{
        opacity: 1;
        transition-duration: 0.6s;
    }
    #linkMenu{
        width: 100%;
        height: 65%;
        top: 0;
    }
    #divLogo{
        width: 60%;
        height: 20%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-align-self: center;
                align-self: center;
    }
    #name{
        font-size: 3vw;
    }
    .menuItem>a{
        -webkit-transform: translateY(0);
                transform: translateY(0);
        font-size: 4vw;
    }
    #logoFox{
        z-index: 5;
        -webkit-transform: scale(1);
                transform: scale(1);
        max-height: 60%;
        justify-self: end;
    }
    #closeMenu{
        margin: 10px;
    }
    #availableLanguages{
        display: none;
        width: 100%;
        z-index: 4;
        height: 10%;
        top: 54%;
    }
    #availableLanguages>p{
        margin: 10px;
        padding: 3%;
        font-size: 4vw;
    }
    #fr{
        text-align: end;
    }
  }
@font-face {
font-family: 'Bahnschrift Regular';
src: local('Bahnschrift Regular'), url(/static/media/BAHNSCHRIFT.42ee57d5.woff) format('woff');
}
.cls2-1{
    fill: #ffffff;
}
.cls2-2{
    opacity:0.6;
}
.cls2-3,.cls2-4{
    fill:none;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:6px;
}
.cls2-4{
    opacity:0.6;
    isolation:isolate;
}
#TitreAcceuil{
    height: 100vh;
    width: 50vw;
    justify-self: center;
    z-index: 4;
}
#T1, #T2, #T1Ombre, #T2Ombre{
    opacity: 0;
}
#Trace1, #Trace1Ombre, #Trace2, #Trace2Ombre{
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
}
#AcceuilInf, #AcceuilSup{
    opacity: 0;
}

@media (max-width: 768px) {
    #TitreAcceuil {
      width: 80%;
    }
  }
.section{
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: grid;
}

main {
    z-index: 16;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}
main::-webkit-scrollbar { width: 0 !important }
main { overflow: -moz-scrollbars-none; }
main { -ms-overflow-style: none; }

#home{
    background: linear-gradient(
90deg
, rgba(117,217,198,1) 0%, rgba(80,163,162,1) 100%);
}
#about{
    background: linear-gradient(
90deg
, rgba(112,212,193,1) 0%, rgba(75,158,157,1) 100%);
}
#creations{
    background: linear-gradient(
        90deg
        , rgba(107,207,188,1) 0%, rgba(70,153,154,1) 100%);
}
#contact{
    background: linear-gradient(
90deg
, rgba(102,202,183,1) 0%, rgba(65,148,147,1) 100%);
}
#home>canvas{
    position: relative;
    top: -100vh;
    left: 0;
    z-index: 1;
}
.courbe{
    fill:none;
    stroke:#ffffff;
    stroke-miterlimit:10;
    stroke-width:6px;
    stroke-dasharray: 2350;
    stroke-dashoffset: 2350;
}
.pathfinder{
    fill:none;
    stroke:#ffffff;
    stroke-miterlimit:10;
    stroke-width:27px;
}
.dotSVG{
    cursor: pointer;
    fill:#fff;
    stroke:#fff;
    stroke-width:5px;
    transition-duration: 0.4s;
}
#scollForcer{
    height: 321vh;
    width: 0px;
}
#scroller{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
#svgPosition{
    position: -webkit-sticky;
    position: sticky;
    overflow: visible;
    width: 100vw;
    height: 100vh;
    top: 0vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
#courbeAboutSVG{
    position: relative;
    top: -5%;
}

#scroller::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  #scroller {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  #text-circle{
    width: 100vw;
    height: 56.25vw;
    position: relative;
    top: 12%;
    right: 33%;
  }
  #text-circle>p{
      color: white;
      font-size: 6vmin;
  }
  #text-circle>p:first-of-type{
      position: relative;
      top: 24%;
      left: 10%;
  }
  .titleCircle{
      font-size: 0;
  }
#about{
    grid-template-columns: 33% 1fr;
    grid-template-rows: 15% 1fr 10%;
    grid-template-areas:
        "a b"
        "a c"
        "d d";
}

h3{
    width: 75%;
    color: white;
    font-size: 2.5vw;
    font-weight: 100;
    font-stretch: semi-expanded;
    grid-area: c;
    font-family: Bahnschrift Regular;
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
}
#hello{
    font-size: 8vmin;
    font-weight: 900;
}
#scrollText{
    fill:none;
    stroke: #faf5f5;
    stroke-miterlimit:10;
    stroke-width: 126px;
    stroke-dasharray: 250;
    stroke-dashoffset: 150;
}
#scollIndicator{
    height: 6vh;
    width: auto;
    grid-area: d;
    justify-self: center;
    align-self: center;
}
.ScrollLowOpacity{
    fill: white;
    opacity: 0.6;
}
#arrowDownIndicator{
    fill: white;

}
#creations{
    grid-template-columns: 1fr 68% 1fr;
}
#gallery{
    min-width: 68vw;
    height: 80vh;    
    margin-top: 12vmin;
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    overflow: auto;
    opacity: 0;
    display: none;
}
#gallery::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(90, 180, 180);
}

#gallery::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(90, 180, 180);
}

#gallery::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(77, 168, 163);
}
#buttonGallery{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: auto;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 15vmin;
    -webkit-transform: rotate(90deg) translateY(-40vw)  translateX(-5vw);
            transform: rotate(90deg) translateY(-40vw)  translateX(-5vw);
    z-index: 5;
}
#buttonGallery>div{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
#buttonGallery>div>p{
    margin-bottom: 20%;
    margin-top: 15%;
    color: white;    
    font-family: Bahnschrift Regular;
    font-size: 1.8vmin;
    text-align: center;
}
#buttonGallery>div>span{
    transition-duration: 0.3s;
    border: 0px solid #ffffff;
    height: 17vmin;
    width: 17vmin;
    background-color: #edebe6;
    border-radius: 50%;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.4);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
#buttonGallery>div>span:hover{
    cursor: pointer;
    background: rgba(90, 90, 90, 0.7);
}
#buttonGallery>div>span:hover svg{
    fill: #ffffff;
    width: auto;
}
#buttonGallery>div>span.active{
    border: 2px solid #f5f3f0;
    background: rgba(27, 27, 28, 0.7);
}
#buttonGallery>div>span.active>svg>*{
    fill: #f2eeed;
}
span>svg{
    transition-duration: 0.2s;
    height: 70%;
    width: auto;

}

.react-photo-gallery--gallery>div>img{
    cursor: pointer;
    opacity: 0.9;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}



@media (max-width: 768px) {
    #buttonGallery{
        margin-top: 30vmin;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    #creations{
        grid-template-columns: 1fr
    }
    #gallery{
        height: 60vh;
        margin-top: 0;
        min-width: 90vw;
    }
    #buttonGallery>div{
        margin: 10px;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    #buttonGallery>div>span{
        height: 17vmin;
        width: 17vmin;
    }
    #buttonGallery>div>p{
    font-size: 3vmin;
    }
}
#contact{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: 12vh 1fr;
    grid-template-areas: "a a" "b c";
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-family: Bahnschrift Regular;
}
#contact>div{
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
#social-network{
    margin-top: 3%;
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
#social-network>a{
    margin: 3%;
}
#social-network>a>svg{
    height: 3vmin;
    width: auto;
    fill: #e8eaed;
    opacity: 0.9;
}

#photo-tres-hot{
    border-radius: 40px;
    opacity: 0.9;
    margin-bottom: 40px;
    max-width: 65%;
    max-height: 60%;
    border: 3px solid #ffffff;
}
#contact-link{
    grid-area: b;
    height: 100%;
}
#contact-link>p:first-of-type{
    cursor: pointer;
}
#contact>div:nth-child(2){
    grid-area: c;
    justify-self: left;
}
#link-resume{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
#link-resume>a{
    margin: 0px 10px;
    font-size: 1.5vmin;
    cursor:pointer;    
    text-decoration: underline;
    color: white;
}
#contact-link>p{
    font-size: 2.5vmin;
}
#contact-link>p:first-of-type{
    font-size: 4vmin;
    /* user-select: auto;
    -moz-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto; */
}
#contact-link>:last-child{
    margin-top: 20%;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.6vmin;
}
.line{
    fill:#ffffff;stroke:#ffffff;stroke-width:2.5;stroke-miterlimit:10
}
#mail-svg>svg{
    fill: #ffffff;
    width: 13%;
    margin-top: 20px;
}
#mail-svg{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}




@media (max-width: 768px) {
    #contact{
        grid-template-columns: 100%;
        grid-template-rows: 12vh -webkit-min-content 1fr;
        grid-template-rows: 12vh min-content 1fr;
        grid-template-areas:
        "a"
        "c"
        "b";
    }
    #photo-tres-hot{
        max-width: 45%;
        margin-top: 40px;
        margin-bottom: 15px;
    }
    #contact-link>:last-child{
        margin-top: 10%;
    }
    #contact>div:nth-child(2){
        justify-self: center;
    }
    #social-network>a>svg{
        height: 5vmin;
    }
    #mail-svg {
        height: 20%;
    }
    #contact>div{
        -webkit-justify-content: start;
                justify-content: start;
    }
    #contact-link>p {
        font-size: 3.5vmin;
    }
    #link-resume>a {
        font-size: 2.5vmin;
    }
}
#study{
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    background-color: rgb(77, 168, 163);
    grid-template-areas: "a b c";
    width: 100vw;
    height: auto;
    min-height: 100vh;
}
#study-content{
    background: rgba(75,158,157,1);
    width: 100%;
    grid-area: b;
}
