#creations{
    grid-template-columns: 1fr 68% 1fr;
}
#gallery{
    min-width: 68vw;
    height: 80vh;    
    margin-top: 12vmin;
    justify-self: center;
    align-self: center;
    overflow: auto;
    opacity: 0;
    display: none;
}
#gallery::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(90, 180, 180);
}

#gallery::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(90, 180, 180);
}

#gallery::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(77, 168, 163);
}
#buttonGallery{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 15vmin;
    transform: rotate(90deg) translateY(-40vw)  translateX(-5vw);
    z-index: 5;
}
#buttonGallery>div{
    transform: rotate(-90deg);
}
#buttonGallery>div>p{
    margin-bottom: 20%;
    margin-top: 15%;
    color: white;    
    font-family: Bahnschrift Regular;
    font-size: 1.8vmin;
    text-align: center;
}
#buttonGallery>div>span{
    transition-duration: 0.3s;
    border: 0px solid #ffffff;
    height: 17vmin;
    width: 17vmin;
    background-color: #edebe6;
    border-radius: 50%;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
#buttonGallery>div>span:hover{
    cursor: pointer;
    background: rgba(90, 90, 90, 0.7);
}
#buttonGallery>div>span:hover svg{
    fill: #ffffff;
    width: auto;
}
#buttonGallery>div>span.active{
    border: 2px solid #f5f3f0;
    background: rgba(27, 27, 28, 0.7);
}
#buttonGallery>div>span.active>svg>*{
    fill: #f2eeed;
}
span>svg{
    transition-duration: 0.2s;
    height: 70%;
    width: auto;

}

.react-photo-gallery--gallery>div>img{
    cursor: pointer;
    opacity: 0.9;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}



@media (max-width: 768px) {
    #buttonGallery{
        margin-top: 30vmin;
        flex-direction: row;
        transform: rotate(0);
    }
    #creations{
        grid-template-columns: 1fr
    }
    #gallery{
        height: 60vh;
        margin-top: 0;
        min-width: 90vw;
    }
    #buttonGallery>div{
        margin: 10px;
        transform: rotate(0);
    }
    #buttonGallery>div>span{
        height: 17vmin;
        width: 17vmin;
    }
    #buttonGallery>div>p{
    font-size: 3vmin;
    }
}