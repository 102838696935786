#curvO{
    stroke-dasharray : 351;
    stroke-dashoffset : 351;
}
#curvL{
    stroke-dasharray : 1510;
    stroke-dashoffset : 1510;
}
#curvI{
    stroke-dasharray : 200;
    stroke-dashoffset : 200;
}
#curvOM{
    stroke-dasharray : 930;
    stroke-dashoffset : 930;
}

h1{
    height: 20vh;
    width: 40vw;
    z-index: 6;
    position: fixed;
    top: 30vh;
    left: 50vw;
    transform: translateX(-50%);
}