.cls2-1{
    fill: #ffffff;
}
.cls2-2{
    opacity:0.6;
}
.cls2-3,.cls2-4{
    fill:none;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:6px;
}
.cls2-4{
    opacity:0.6;
    isolation:isolate;
}
#TitreAcceuil{
    height: 100vh;
    width: 50vw;
    justify-self: center;
    z-index: 4;
}
#T1, #T2, #T1Ombre, #T2Ombre{
    opacity: 0;
}
#Trace1, #Trace1Ombre, #Trace2, #Trace2Ombre{
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
}
#AcceuilInf, #AcceuilSup{
    opacity: 0;
}

@media (max-width: 768px) {
    #TitreAcceuil {
      width: 80%;
    }
  }