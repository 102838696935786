.section{
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: grid;
}

main {
    z-index: 16;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}
main::-webkit-scrollbar { width: 0 !important }
main { overflow: -moz-scrollbars-none; }
main { -ms-overflow-style: none; }

#home{
    background: linear-gradient(
90deg
, rgba(117,217,198,1) 0%, rgba(80,163,162,1) 100%);
}
#about{
    background: linear-gradient(
90deg
, rgba(112,212,193,1) 0%, rgba(75,158,157,1) 100%);
}
#creations{
    background: linear-gradient(
        90deg
        , rgba(107,207,188,1) 0%, rgba(70,153,154,1) 100%);
}
#contact{
    background: linear-gradient(
90deg
, rgba(102,202,183,1) 0%, rgba(65,148,147,1) 100%);
}