#about{
    grid-template-columns: 33% 1fr;
    grid-template-rows: 15% 1fr 10%;
    grid-template-areas:
        "a b"
        "a c"
        "d d";
}

h3{
    width: 75%;
    color: white;
    font-size: 2.5vw;
    font-weight: 100;
    font-stretch: semi-expanded;
    grid-area: c;
    font-family: Bahnschrift Regular;
    transform: translateX(-150%);
}
#hello{
    font-size: 8vmin;
    font-weight: 900;
}
#scrollText{
    fill:none;
    stroke: #faf5f5;
    stroke-miterlimit:10;
    stroke-width: 126px;
    stroke-dasharray: 250;
    stroke-dashoffset: 150;
}
#scollIndicator{
    height: 6vh;
    width: auto;
    grid-area: d;
    justify-self: center;
    align-self: center;
}
.ScrollLowOpacity{
    fill: white;
    opacity: 0.6;
}
#arrowDownIndicator{
    fill: white;

}