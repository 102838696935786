#contact{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: 12vh 1fr;
    grid-template-areas: "a a" "b c";
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Bahnschrift Regular;
}
#contact>div{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
#social-network{
    margin-top: 3%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
#social-network>a{
    margin: 3%;
}
#social-network>a>svg{
    height: 3vmin;
    width: auto;
    fill: #e8eaed;
    opacity: 0.9;
}

#photo-tres-hot{
    border-radius: 40px;
    opacity: 0.9;
    margin-bottom: 40px;
    max-width: 65%;
    max-height: 60%;
    border: 3px solid #ffffff;
}
#contact-link{
    grid-area: b;
    height: 100%;
}
#contact-link>p:first-of-type{
    cursor: pointer;
}
#contact>div:nth-child(2){
    grid-area: c;
    justify-self: left;
}
#link-resume{
    display: flex;
    flex-direction: row;
}
#link-resume>a{
    margin: 0px 10px;
    font-size: 1.5vmin;
    cursor:pointer;    
    text-decoration: underline;
    color: white;
}
#contact-link>p{
    font-size: 2.5vmin;
}
#contact-link>p:first-of-type{
    font-size: 4vmin;
    /* user-select: auto;
    -moz-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto; */
}
#contact-link>:last-child{
    margin-top: 20%;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.6vmin;
}
.line{
    fill:#ffffff;stroke:#ffffff;stroke-width:2.5;stroke-miterlimit:10
}
#mail-svg>svg{
    fill: #ffffff;
    width: 13%;
    margin-top: 20px;
}
#mail-svg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




@media (max-width: 768px) {
    #contact{
        grid-template-columns: 100%;
        grid-template-rows: 12vh min-content 1fr;
        grid-template-areas:
        "a"
        "c"
        "b";
    }
    #photo-tres-hot{
        max-width: 45%;
        margin-top: 40px;
        margin-bottom: 15px;
    }
    #contact-link>:last-child{
        margin-top: 10%;
    }
    #contact>div:nth-child(2){
        justify-self: center;
    }
    #social-network>a>svg{
        height: 5vmin;
    }
    #mail-svg {
        height: 20%;
    }
    #contact>div{
        justify-content: start;
    }
    #contact-link>p {
        font-size: 3.5vmin;
    }
    #link-resume>a {
        font-size: 2.5vmin;
    }
}