.courbe{
    fill:none;
    stroke:#ffffff;
    stroke-miterlimit:10;
    stroke-width:6px;
    stroke-dasharray: 2350;
    stroke-dashoffset: 2350;
}
.pathfinder{
    fill:none;
    stroke:#ffffff;
    stroke-miterlimit:10;
    stroke-width:27px;
}
.dotSVG{
    cursor: pointer;
    fill:#fff;
    stroke:#fff;
    stroke-width:5px;
    transition-duration: 0.4s;
}
#scollForcer{
    height: 321vh;
    width: 0px;
}
#scroller{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
#svgPosition{
    position: sticky;
    overflow: visible;
    width: 100vw;
    height: 100vh;
    top: 0vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#courbeAboutSVG{
    position: relative;
    top: -5%;
}

#scroller::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  #scroller {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  #text-circle{
    width: 100vw;
    height: 56.25vw;
    position: relative;
    top: 12%;
    right: 33%;
  }
  #text-circle>p{
      color: white;
      font-size: 6vmin;
  }
  #text-circle>p:first-of-type{
      position: relative;
      top: 24%;
      left: 10%;
  }
  .titleCircle{
      font-size: 0;
  }